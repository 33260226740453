.innerNewsContainer {
  padding: 6rem 8rem;
  @media (max-width: 960px) {
    padding: 5rem 6.1rem;
  }
  @media (max-width: 425px) {
    padding: 4rem 4rem;
  }

  .header {
    max-width: 880px;
    margin: 0 auto;
    h1 {
      font-weight: 600;
      font-size: 5.6rem;
      line-height: 8.5rem;
      color: #0e1428;
    }
  }
  .newsImage {
    width: 100%;
    max-width: 1440px;
    max-height: 47.4rem;
    margin: auto;
    overflow: hidden;
    border-radius: 1rem;
    margin-bottom: 6rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-height: inherit;
      transition: all 0.3s linear;
    }
    img:hover {
      transform: scale(1.2, 1.2);
      transition: all 0.3s linear;
    }
  }
  .blogHeader {
    display: flex;
    align-items: center;
    gap: 3rem;
    font-weight: 300;
    color: #000000;
    .blogLink {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      a {
        color: #6f6f6f;
        font-size: 1.3rem;
        line-height: 1.5rem;
        font-weight: 400;
      }
      p {
        color: #cccccc;
        @extend a;
      }
    }

    > p {
      font-weight: 400;
      font-size: 2.2rem;
      color: #000000;
    }
  }

  .newsTitle {
    margin-top: 2rem;
  }

  .blogNewsSection {
    .mainText {
      display: flex;
      gap: 15rem;
      @media (min-width: 1440px) {
        justify-content: center;
      }
      @media (max-width: 900px) {
        gap: 8rem;
      }
      @media (max-width: 768px) {
        gap: 4rem;
      }

      @media (max-width: 700px) {
        gap: 2rem;
      }
      @media (max-width: 600px) {
        flex-wrap: wrap;
      }
      max-width: 1440px;
      margin: 0 auto;

      .leftSide {
        min-width: 200px;
        @media (max-width: 700px) {
          min-width: 180px;
        }
        @media (max-width: 600px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
        .blogWriterDetails {
          display: flex;
          gap: 1rem;
          margin-bottom: 3.3rem;
          @media (max-width: 600px) {
            margin-bottom: 0rem;
          }

          .writer {
            h2 {
              font-weight: 400;
              font-size: 1.8rem;
              line-height: 180%;
              color: #011a40;
            }
            p {
              font-weight: 300;
              font-size: 1.4rem;
              font-weight: 300;
              line-height: 139.94%;
              color: #011a40;
            }
          }
        }
        section {
          > p {
            font-weight: 400;
            color: #011a40;
            font-size: 1.8rem;
            line-height: 161.5%;
          }
        }
      }

      .icon {
        display: flex;
        gap: 4rem;
        margin-top: 1rem;

        a {
          display: flex;
          background-color: #f9f9f9;
          width: 5rem;
          height: 5rem;
          justify-content: center;
          align-items: center;
        }
      }

      .blogArticle {
        .authorNameOnMobile {
          display: none;
        }
        p {
          font-weight: 400;
          color: #333538;
          font-size: 2rem;
          line-height: 180%;
          margin-bottom: 1.4rem;
          max-width: 790px;
        }
      }
    }
  }
  .blogConclusion {
    max-width: 1140px;
    margin: auto;
    margin-bottom: 6rem;
    h2 {
      font-weight: 500;
      font-size: 3.6rem;
      line-height: 8.5rem;
      color: #0e1428;
    }
    p {
      font-weight: 400;
      color: #333538;
      font-size: 2rem;
      line-height: 180%;
    }
  }

  .moreNews {
    font-weight: 500;
    font-size: 2.8rem;
    margin-top: 2rem;
    color: #0e1428;
  }
}

.moreArticle {
  padding: 6rem 8rem;
  background-color: #e6e7ec;
  @media (max-width: 960px) {
    padding: 5rem 6.1rem;
  }
  @media (max-width: 425px) {
    padding: 4rem 4rem;
  }

  h3 {
    font-weight: 500;
    font-size: 3.6rem;
    line-height: 85px;
    color: #0e1428;
    margin: auto;
    margin-bottom: 2rem;
    max-width: 1440px;
  }
}

.cardContainer {
  column-gap: 6rem;
  row-gap: 8rem;
  width: 100%;
  max-width: 1440px;
  display: grid;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  justify-content: space-around;

  h1 {
    display: none;
    text-align: none;
  }
}

.card {
  transition: 0.3s;
  height: auto;
  > div:first-child {
    width: 100%;
    max-height: 31rem;
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s linear;
    }
    img:hover {
      transform: scale(1.1, 1.1);
      transition: all 0.3s linear;
    }
  }

  > div:last-child {
    span {
      display: flex;
      align-items: center;
      gap: 2.5rem;
      margin-bottom: 1.5rem;

      > p {
        font-weight: 400;
        font-size: 2rem;
      }
    }
    h5 {
      font-weight: 700;
      font-size: 2.4rem;
      color: #0e1428;
      margin-bottom: 1rem;
    }
    a {
      font-weight: 400;
      font-size: 2.2rem;
      text-decoration-line: underline;
      color: #3ec5f0;
    }
  }
}

.card:hover {
  cursor: pointer;
}
