.navbar {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background-color: white;
  // border: 1px solid red;
  /* margin-top: 2rem; */

  position: fixed;
  width: 100%;
  color: #373435;
  top: 0;
  z-index: 999;
}
.navbarr {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  width: 100%;
  color: #373435;
  top: 0;
  padding: 0 8rem;
  
}
.contact {
  color: #3ec5f0;
  padding: 2rem 5rem;
  border: 1px solid #3ec5f0;
  background-color: transparent;
  
  font-size: 2rem;
  border-radius: 10px;
  transition: all 0.3s ease-in;
}

.contact:hover {
  color: #2185a4;
  border-color: #2185a4;
  transition: all 0.3s ease-out;
}
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  
}
.container {
  
  z-index: 1;
  width: 100%;
  /* max-width: 1300px; */
  max-width: 1400px;
  margin: auto;
  margin-right: auto;
  margin-left: auto;
  
}
.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.navbar-logo img {
  height: 5rem;
  width: inherit;
}
.navbar-icon {
  margin-right: 0.5rem;
}
.nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  font-size: 2.2rem;
  text-align: center;
  width: 75%;
}

@media (min-width: 1440px) {
  .nav-menu {
    justify-content: flex-end;
    column-gap: 5rem;
  }
}
.nav-item {
  /* height: 80px;
  border-bottom: 2px solid transparent; */
  font-weight: 300;
}

.nav-links {
  color: #373435;
  display: flex;
  align-items: center;
  text-decoration: none;
  /* line-height: 8.5rem; */
  padding: 0.5rem 1rem;
  /* height: 100%; */
  font-weight: 300;
  font-size: 2rem;
}

.fa-bars {
  color: #fff;
  font-size: 6rem;
}

.menu-icon {
  display: none;
}

.socialMediaIcon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 80px;
    left: -100%;
    opacity: 1;
    z-index: 20;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: white;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #3ec5f0;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
    > div {
      margin: 0 auto;
    }
  }

  .container {
    padding: 0 6.1rem;
  }

  .menu-icon {
    display: block;
    /* position: absolute; */
    /* top: 0; */
    /* right: 0; */
    /* transform: translate(-100%, 60%); */
    font-size: 3rem;
    cursor: pointer;
    color: black;
  }
  .fa-times {
    color: black;
    font-size: 6rem;
  }
  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }

  .socialMediaIcon {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
  }
}
