.container {
  padding: 6rem 23rem;
  max-width: 1400px;
  margin: auto;
  @media (max-width: 960px) {
    padding: 4.5rem 10rem;
  }
  @media (max-width: 425px) {
    padding: 4rem 4rem;
  }

  h1 {
    font-weight: 700;
    font-size: 5rem;
    line-height: 7rem;
    text-transform: uppercase;
    color: #001451;
  }

  > div {
    max-width: 1440px;
    margin: 0 auto;
  }

  .line {
    width: 10rem;
    height: 1px;
    background-color: #000000;
    display: block;
    margin-bottom: 6rem;
  }

  .mainContent {
    .image_wrap {
      width: 100%;
      max-width: 1012px;
      max-height: 454px;
      min-height: 300px;
      border-radius: 1rem;
      margin: 0 auto;
      overflow: hidden;
      margin-bottom: 5rem;

      img {
        width: 100%;
        height: 100%;
        min-height: inherit;
        object-fit: cover;
        object-position: center top;
        background-color: lightgray;
        transition: all 0.3s ease-in-out;
      }

      img:hover {
        transform: scale(1.2, 1.2);
        transition: all 0.3s ease-in-out;
      }
    }

    .text_wrap {
      max-width: 837px;
      margin: 0 auto;
      p {
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 140%;
        text-align: justify;
        color: #333538;
        margin-bottom: 3rem;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3rem;
        gap: 6px;
        a {
          font-weight: 400;
          font-size: 2.8rem;
          line-height: 3.5rem;
          text-decoration-line: underline;
          color: #3ec5f0;
        }
      }
    }

    .image_grid {
      display: grid;
      width: 100%;
      max-width: 837px;
      margin: 0 auto;
      margin-bottom: 5rem;
      gap: 1.8rem;
      grid-template-columns: 30rem auto;
      grid-template-areas:
        "one two"
        "three two";
      @media (max-width: 700px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          "one three"
          "two two";
      }

      div {
        min-height: 300px;
        border-radius: 1rem;
        overflow: hidden;
        @media (max-width: 700px) {
          max-height: 300px;
        }
        @media (max-width: 500px) {
          min-height: 150px;
          max-height: 150px;
        }
        img {
          object-fit: cover;
          object-position: center top;
          width: 100%;
          height: 100%;
          transition: all 0.3s ease-in-out;
        }
        img:hover {
          transform: scale(1.2, 1.2);
          transition: all 0.3s ease-in-out;
        }
      }
      .image0 {
        grid-area: one;
        width: 100%;
        overflow: hidden;
      }
      .image1 {
        grid-area: two;
      }
      .image2 {
        grid-area: three;
      }
    }
  }
}
