@use "./variables.scss" as varr;

@font-face {
  font-family: "Airbnb Cereal";
  src: url("../fonts/AirbnbCerealMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Airbnb Cereal";
  src: url("../fonts/AirbnbCerealBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Airbnb Cereal";
  src: url("../fonts/AirbnbCerealExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Airbnb Cereal";
  src: url("../fonts/AirbnbCerealBook.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Airbnb Cereal";
  src: url("../fonts/AirbnbCerealLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;

  @media screen and (max-width: 1200px) {
    font-size: 55%;
  }

  @media screen and (max-width: 1000px) {
    font-size: 50%;
  }

  @media screen and (max-width: 700px) {
    font-size: 45%;
  }
}

body {
  font-family: varr.$app-font, sans-serif;
  font-weight: varr.$font__wight;
  color: varr.$text_color;
  background-color: varr.$main__backg;
  font-size: varr.$main_font;
}

a {
  text-decoration: none;
  color: varr.$text_color;
}

i {
  font-size: 2.5rem;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

button {
  cursor: pointer !important;
  transition: all 0.3s ease;
}

button:active {
  transform: scale(0.9, 0.9);
  transition: all 0.3s ease;
}

button:hover {
  transform: scale(1.05, 1.05);
  background-color: #0a014f !important;
  color: white !important;
  transition: all 0.3s ease;
  border: none;
}

// Animations
.fade_in {
  animation: fade-in 0.6s linear 0s 1 forwards;
}
.fade_in_up {
  animation: fade-in-up 0.6s linear 0s 1 forwards;
}

.fade_in_blur {
  animation: fade-in-blur 1s cubic-bezier(0.11, 0, 0.5, 0) 0s 1;
}

.slide_in_left {
  animation: slide-in 0.4s linear 0s 1 forwards;
}

.slide_in_right {
  animation: slide-in-reverse 0.4s linear 0s 1 forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(150px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fade-in-blur {
  0% {
    opacity: 0;
    filter: blur(4px);
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: scale(1);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slide-in-reverse {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
