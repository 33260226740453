@use "../../globalStyles/variables.scss" as varr;

.container {
  display: flex;
  gap: 4.3rem;
  width: 100%;
  flex-wrap: wrap;
}

.carousel {
  min-height: 300px;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-basis: calc(60% - 4.3rem);
  @media (max-width: 900px) {
    flex-basis: 100%;
  }
}

.list {
  width: 100%;
  max-width: 510px;
  flex-basis: calc(40% - 4.3rem);
  @media (max-width: 900px) {
    flex-basis: 100%;
  }
  article {
    margin-bottom: 2rem;
    opacity: 0.3;
    transition: all 0.3s linear;

    h3 {
      font-weight: 700;
      font-size: 2.5rem;
      color: #000000;
      @media (max-width: 1024px) {
        font-size: 2.6rem;
      }
    }
    p {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 180%;
      color: #333538;
      @media (max-width: 1024px) {
        font-size: 2rem;
      }
    }
  }
}

.imgContainer {
  position: relative;
  width: 100%;
  height: 52rem;
  border-radius: 1rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    transition: all 0.3s linear;
    position: absolute;
    top: 0;
  }
  img:hover {
    transform: scale(1.1, 1.1);
    transition: all 0.3s linear;
  }
}

.fade_in {
  animation: fadeIn 1s linear 0s 1;
}
.fade_out {
  animation: fadeOut 0.8s ease-out 0s 1 forwards;
}
.slide_in {
  z-index: 2;
  animation: slide-in 0.5s ease-in 0s 1 forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    left: 0;
  }
  // 50% {
  //   opacity: 0;
  // }
  100% {
    opacity: 0;
    left: -100%;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    right: -100%;
  }
  50% {
    opacity: 1;
  }
  100% {
    right: 0;
  }
}
.dots {
  display: flex;
  align-items: center;
  gap: 1.3rem;
  position: absolute;
  z-index: 3;
  bottom: 4rem;
  right: 5rem;
}

.dot {
  display: inline-block;
  width: 12.6px;
  height: 12.6px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: all 0.3s linear;
}

.active_dot {
  background-color: #3ec5f0;
  border-radius: 6.3px;
  width: 35px;
  transition: all 0.3s linear;
}

.wrap {
  padding: 5rem 8rem;

  > section {
    max-width: 1400px;
    @media (min-width: 1400px) {
      margin: 0 auto;
    }
  }

  @media (max-width: 960px) {
    padding: 4rem 6.1rem;
  }
  @media (max-width: 425px) {
    padding: 4rem 4rem;
  }

  h1 {
    font-weight: 700;
    font-size: 4rem;
    // line-height: 7.3rem;
    max-width: 550px;
    margin-bottom: 3rem;
    @media (max-width: 768px) {
      font-size: 4.6rem;
      line-height: 6.3rem;
      max-width: 350px;
    }
  }
}
