@import url("https://fonts.googleapis.com/css2?family=Macondo&family=Roboto:wght@100;400;500;700&family=Tapestry&display=swap");

$app-font: "Airbnb Cereal", sans-serif;

// $font-family1:
$main_font: 1.6rem;
$header_gap: 3rem;
$link_gap: 2rem;
$font__wight: 400;
$general_gap: 3rem;
$padding: 3rem;

// color
$header__backg: rgb(255, 255, 255);
$main__backg: rgb(255, 255, 255);
$product__backg: #cdd1ce;
$modal__backg: #4b5548c5;
$btn__backg: #3ec5f0;
$btn__hover: #2185a4;
$card__bg1: #0a014f;
$card__bg2: #3ec5f0;
$footer_bg: #0e1428;
$contactusPage_bg: #060e24;

$text_animation: #3ec5f0;
$text_color: #373435;

$p_colour: #333538;
$p_lineheight: 180%;

$box_shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.4);

$border_radius_main: 0.5rem;
$breakpoint__tablet: 768px;
$breakpoint__mobile: 475px;

$transition: all 0.3s linear;
