@use "../../globalStyles/variables.scss" as varr;

.teamsDetailsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8rem;
}

.teamsDetails {
  padding: 5rem 8rem;
  text-align: center;

  @media (max-width: 960px) {
    padding: 4.5rem 6.1rem;
  }
  @media (max-width: 425px) {
    padding: 4rem 4rem;
  }

  > h1 {
    font-size: 5.6rem;
    margin-bottom: 18px;
    color: #0e1428;
  }

  > p {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 180%;
    max-width: 720px;
    margin: 0 auto;
    margin-bottom: 2.6rem;
    color: varr.$p_colour !important;
  }

  > p:last-child {
    max-width: 860px;
  }

  > div {
    width: 100%;
    max-width: 1190px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 3rem;
    border-radius: 1rem;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      transition: 0.3s all ease-out;
    }

    img:hover {
      transform: scale(1.1, 1.1);
      transition: 0.3s all ease-in;
    }
  }
}

.leaders {
  background-color: #fafafb;
  padding: 10rem 8rem;

  > article {
    max-width: 1400px;
    @media (min-width: 1400px) {
      margin: auto;
    }
  }

  > article:first-child {
    margin-bottom: 6rem;

    h2 {
      font-size: 4rem;
      font-weight: 700;
      color: #0a014f;
      margin-bottom: 2rem;
    }

    p {
      max-width: 540px;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 180%;
      color: #000000;
    }
  }

  @media (max-width: 960px) {
    padding: 8rem 6.1rem;
  }
  @media (max-width: 425px) {
    padding: 5rem 4rem;
  }

  .leadership {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 12rem;

    @media (max-width: 849px) {
      justify-content: center;
      gap: 3rem;
    }

    > div {
      > section {
        height: 30rem;
        width: 35rem;
        @media (max-width: 1249px) {
          height: 28rem;
          width: 32rem;
        }
        @media (max-width: 1100px) {
          height: 25rem;
          width: 30rem;
        }
        border-radius: 1rem;
        overflow: hidden;
        margin-bottom: 2rem;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center top;
          transition: all 0.3s ease-out;
        }
        > img:hover {
          transform: scale(1.1, 1.1);
          transition: all 0.3s ease-in;
        }
      }

      span {
        display: flex;
        column-gap: 1.6rem;
        align-items: center;
        h3 {
          font-weight: 500;
          font-size: 2rem;
        }
        a {
          height: fit-content;
        }
      }
      p {
        font-weight: 400;
        font-size: 1.6rem;
      }
    }
  }
}
.ceoDetailss {
  background-color: #f8f8f8;
}
.ceoDetails {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f8f8f8;
  width: 100%;
  height: 83rem;
  max-width: 1400px;
  margin: auto;
  @media (max-width: 767px) {
    height: fit-content;
  }

  .letterfromtheCEO {
    text-align: justify;
  }

  section:first-child {
    width: 54%;
    padding: 8rem 8rem;
    padding-top: 25rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 767px) {
      width: 100%;
      min-height: 400px;
    }

    @media (max-width: 960px) {
      padding: 4.5rem 6.1rem;
      padding-top: 25rem;
    }
    @media (max-width: 767px) {
      padding-top: 4rem;
    }
    @media (max-width: 425px) {
      padding: 4rem 4rem;
    }

    > div {
      margin-bottom: 5rem;
      h2 {
        font-weight: 500;
        font-size: 2.8rem;
        margin-bottom: 2.5rem;
      }
      p {
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 180%;
        max-width: 504px;
      }
    }
    h3 {
      border-bottom: 1px solid #d5d5d5;
      max-width: 418px;
      font-weight: 400;
      font-size: 1.8rem;
      padding-bottom: 8px;
      color: #1f2f5e;
    }
  }

  section:last-child {
    width: 46%;
    overflow: hidden;
    height: 100%;
    @media (max-width: 767px) {
      width: 100%;
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
      transition: all 0.3s ease-out;
    }

    img:hover {
      transform: scale(1.1, 1.1);
      transition: all 0.3s ease-in;
    }
  }
}
