@use "../../globalStyles/variables.scss" as varr;

.landingPageMiddleDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 8.4rem;
  padding: 0 8rem;
  @media (max-width: 960px) {
    padding: 0 6.1rem;
  }
  @media (max-width: 425px) {
    padding: 0 4rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 4.8rem;
    color: #0a014f;
  }

  p {
    width: 42rem;
    font-size: 1.8rem;
    color: #333538;
    line-height: 140%;
  }
}

.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  padding: 0 8rem;
  @media (max-width: 960px) {
    padding: 0 6.1rem;
  }
  @media (max-width: 868px) {
    flex-wrap: wrap;
  }
  @media (max-width: 425px) {
    padding: 0 4rem;
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  max-width: 630px;
  min-width: 300px;
  height: fit-content;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  margin-bottom: 8rem;
  margin-top: 3rem;
  section {
    height: 25rem;
    width: 100%;
    overflow: hidden;

    > img {
      // object-fit: cover;
      // object-position: center;
      // height: 100%;
      width: 100%;
      transition: 0.3s all ease-out;
    }
    > img:hover {
      transform: scale(1.3, 1.3);
      transition: 0.3s all ease-out;
    }
  }
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  // transform: scale(1.03);
  transition: all 0.5s;
  cursor: pointer;
}

.container1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  padding: 2.6rem 3rem;
  background-color: varr.$card__bg1;
  color: #ffffff;
  min-height: 33rem;
  > section {
    height: 38rem;
    @media (max-width: 868px) {
      height: fit-content;
    }
  }
}
.container2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  padding: 2.6rem 3rem;
  background-color: #197bbd;
  color: #ffffff;
  min-height: 33rem;
  > section {
    height: 38rem;
    @media (max-width: 868px) {
      height: fit-content;
    }
  }
}

.cardTitle {
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: 600;
  margin-bottom: 14px;
}
.smallText {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 140%;
}

.button {
  width: fit-content;
  border: none;
  background-color: transparent;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
}
