@use "../../globalStyles/variables.scss" as varr;
.mainContainer {
  background: varr.$footer_bg;
  color: #fff;
  padding: 9rem 8rem;
}

.maxWidthSection {
  max-width: 1400px;
  margin: auto;
  color: #fff;
}
.container {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 6rem;
  // padding: 4% 0;
  padding-bottom: 5.3rem;
}

.nxtLogo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 12rem;
  margin-bottom: 4.7rem;
}
.icons {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.leftSide {
  justify-content: left;
  display: flex;
  flex-direction: column;
  width: 35%;
  // gap: 2rem;
  .inputSpan {
    display: flex;
    max-width: 439px;
    input {
      width: 100%;
      font-size: 1.8rem;
      border: none;
      background: #fff;
      padding: 2rem;
      border-radius: 10px 0 0 10px;
      outline-width: 0;
    }
    button {
      font-size: 1.8rem;
      font-weight: 700;
      background: varr.$btn__backg;
      border: none;
      color: white;
      border-radius: 0 10px 10px 0;
      padding: 1rem 2rem;
    }
  }
}
.leftSide h4 {
  font-size: 2rem;
  line-height: 100%;
  color: #ffffff;
  margin-bottom: 1.4rem;
}

.iconsOnMobile {
  display: none;
}
.rightSide {
  display: flex;
  gap: 4rem;
  width: 50%;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2rem;
  .header {
    display: flex;
    flex-direction: column;
    flex: 0.4;
    h4 {
      font-size: 1.4rem;
      // line-height: 170%;
      text-align: left;
      letter-spacing: 0.63px;
      color: #ffffff;
      letter-spacing: 0.63px;
      margin-bottom: 2rem;
    }
    p,
    a {
      font-weight: 300;
      font-size: 1.6rem;
      line-height: 140%;
      text-align: left;
      white-space: nowrap;
      margin-bottom: 1.6rem;
      cursor: pointer;
      color: #ffffff;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.rightSide h4 {
  font-weight: 700;
}

.footerLine {
  width: 100%;
  text-align: center;
  hr {
    height: 1px;
    border: none;
    background-color: #666666;
  }
  p {
    color: #c4c4c4;
  }
}

@media (max-width: 960px) {
  .leftSide {
    width: 43%;
  }
  .mainContainer {
    padding: 5rem 6.1rem;
  }
}

@media (max-width: 783px) {
  .leftSide {
    width: 100%;
  }

  .mainContainer {
    .rightSide {
      width: 100%;
    }
  }
}

@media screen and (max-width: 700px) {
  .mainContainer {
    .rightSide {
      display: flex;
      gap: 2rem;
      width: 100%;
      padding-bottom: 2rem;

      .header {
        display: flex;
        flex-direction: column;
        flex: 0.4;
        // min-width: 40%;
      }
    }
    .icons {
      display: none;
    }
    .iconsOnMobile {
      display: flex;
      gap: 2rem;
    }
    .rightSide {
      padding-bottom: 0;
    }
  }
}
