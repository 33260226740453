.openPositions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  width: 100%;
  max-width: 399px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 3rem;

  .positions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: left;
    padding: 0 1rem;

    .role {
      font-weight: 400;
      font-size: 2.2rem;
      line-height: 2.7rem;
      color: #0e1428;
    }
    .contract {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: #999999;
    }
  }
  a {
    font-weight: 500;
    color: #3ec5f0;
    font-size: 1.6rem;
  }

  .applyLink {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      column-gap: 2rem;
      img {
        width: 0.8rem;
        height: 1.4rem;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}

.openPositions:hover {
  box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease-in-out;
}
