.container {
  width: fit-content;
  > section:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 100%;
    gap: 4px;
    position: relative;
    cursor: pointer;
    div {
      font-weight: 300;
      font-size: 2rem;
    }
  }

  // > section:first-child:hover + .menu {
  //   opacity: 1;
  //   transition: all 0.3s linear;
  // }

  > .menu {
    background: #ffffff;
    box-shadow: 12px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1.6rem 0rem;
    width: 100%;
    max-width: 25rem;
    display:grid;
    grid-template-columns: auto auto;
    position: absolute;
    display: none;
    z-index: 6;
    transition: all 0.3s linear;

    div {
      padding: 0.4rem 3rem;
      margin-bottom: 0.5rem;
      text-align: left;
      font-size: 1.8rem;
      cursor: pointer;
      color: #0a014f;
    }

    div:hover {
      background-color: lightgray;
    }
  }
}
.container:hover .menu {
  display: block;
}
