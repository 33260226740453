@use "../../globalStyles/variables.scss" as varr;

.container {
  > section:first-child {
    text-align: center;
    padding: 5rem 8rem;

    @media (max-width: 960px) {
      padding: 4.5rem 6.1rem;
    }
    @media (max-width: 425px) {
      padding: 4rem 4rem;
    }

    h1 {
      margin-bottom: 1.8rem;
      font-size: 5rem;
      color: #0e1428;
    }

    p {
      max-width: 685px;
      margin: 0 auto;
      margin-bottom: 4.3rem;
      line-height: 206.15%;
      font-weight: 400;
      color: varr.$p_colour;
    }
    > div {
      margin: 0 auto;
      margin-bottom: 5rem;
      width: 100%;
      max-width: 1088px;
      max-height: 553px;
      overflow: hidden;
      border-radius: 1rem;

      img {
        width: 100%;
        max-width: 1088px;
        object-fit: cover;
        object-position: center top;
        transition: 0.3s all ease-in;
      }
      img:hover {
        transform: scale(1.2, 1.2);
        transition: 0.3s all ease-out;
      }
    }
  }
  > .specialization {
    padding: 5rem 8rem;

    @media (max-width: 960px) {
      padding: 4.5rem 6.1rem;
    }
    @media (max-width: 425px) {
      padding: 4rem 4rem;
    }

    h1 {
      margin-bottom: 1.8rem;
      text-align: center;
      font-size: 3rem;
      color: #0e1428;
    }
    ul {
      margin-top: 4.3rem;
      max-width: 550px;
      margin: auto;
    }

    li {
      max-width: 685px;
      margin: 0 auto;
      margin-bottom: 1rem;
      line-height: 206.15%;
      font-weight: 400;
      color: varr.$p_colour;
      span {
        font-weight: bold;
      }
    }
    p {
      max-width: 685px;
      margin: 0 auto;
      margin-top: 4.3rem;
      line-height: 206.15%;
      text-align: center;
      font-weight: 400;
      color: varr.$p_colour;
    }
    > div {
      margin: 0 auto;
      margin-bottom: 5rem;
      width: 100%;
      max-width: 1088px;
      max-height: 553px;
      overflow: hidden;
      border-radius: 1rem;

      img {
        width: 100%;
        max-width: 1088px;
        object-fit: cover;
        object-position: center top;
        transition: 0.3s all ease-in;
      }
      img:hover {
        transform: scale(1.2, 1.2);
        transition: 0.3s all ease-out;
      }
      text-align: center;
      padding: 5rem 8rem;

      @media (max-width: 960px) {
        padding: 4.5rem 6.1rem;
      }
      @media (max-width: 425px) {
        padding: 4rem 4rem;
      }

      h1 {
        margin-bottom: 1.8rem;
        font-size: 5rem;
        color: #0e1428;
      }

      p {
        max-width: 685px;
        margin: 0 auto;
        margin-bottom: 4.3rem;
        line-height: 206.15%;
        font-weight: 400;
        color: varr.$p_colour;
      }
      > div {
        margin: 0 auto;
        margin-bottom: 5rem;
        width: 100%;
        max-width: 1088px;
        max-height: 553px;
        overflow: hidden;
        border-radius: 1rem;

        img {
          width: 100%;
          max-width: 1088px;
          object-fit: cover;
          object-position: center top;
          transition: 0.3s all ease-in;
        }
        img:hover {
          transform: scale(1.2, 1.2);
          transition: 0.3s all ease-out;
        }
      }
    }
  }

  > .offer_and_stands {
    padding-top: 7rem;
    max-width: 1400px;
    margin: auto;
    .button {
      padding: 1.8rem 7rem;
      background-color: transparent;
      border: 1px solid #000000;
      border-radius: 1rem;
      margin-right: 3rem;
      color: #060e24;
      font-size: 2.6rem;
      font-weight: 400;
      margin-bottom: 1.6rem;
    }

    .active {
      background-color: #0a014f;
      color: #ffffff;
      border: none;
    }

    > div:first-child {
      margin-bottom: 6rem;
      padding: 5rem 8rem;

      @media (max-width: 960px) {
        padding: 4.5rem 6.1rem;
      }
      @media (max-width: 425px) {
        padding: 4rem 4rem;
      }
    }
  }

  .stands {
    padding-left: 8rem;
    padding-top: 5rem;

    @media (max-width: 960px) {
      padding-left: 6.1rem;
    }
    @media (max-width: 425px) {
      padding-left: 4rem;
    }

    > section {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }
    }

    .stands_item {
      border-left: 0.775679px solid #373435;
      border-bottom: 0.775679px solid #373435;
      padding: 2.2rem 8rem;
      margin-bottom: 8rem;
      position: relative;
      padding-top: 1.3rem;

      h3 {
        font-weight: 400;
        font-size: 3rem;
        line-height: 140%;
        color: #000000;
        margin-bottom: 2rem;
        opacity: 0.4;
        transition: 0.3s all linear;
      }

      p {
        font-weight: 400;
        font-size: 2rem;
        line-height: 160%;
        color: #000000;
        opacity: 0.4;
      }

      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 9rem;
        height: 9rem;
        position: absolute;
        top: -2rem;
        background-color: #0b034d;
        border-radius: 50%;
        left: -4.5rem;
        transition: 0.3s all linear;
        overflow: hidden;
        img {
          width: 80%;
          height: 80%;
          object-fit: contain;
        }
      }
    }

    .stands_item:hover {
      > span {
        background-color: #0a014f;
        transition: 0.3s all linear;

        svg {
          width: 100% !important;
        }

        svg path {
          fill: #67bcff;
        }
      }

      > h3 {
        opacity: 1;
        font-weight: 500;
        color: #0a014f;
        transition: 0.3s all linear;
      }
      > p {
        opacity: 1;
        transition: 0.3s all linear;
      }
    }
  }

  .offers::-webkit-scrollbar {
    height: 1px;
    background-color: transparent;
  }

  .offers::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .offers {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    scroll-behavior: smooth;

    .offer_item {
      padding: 8rem 5rem;
      padding-bottom: 4rem;
      border-right: 0.775679px solid #373435;
      border-top: 0.775679px solid #373435;
      min-height: 58rem;
      min-width: 48rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        font-weight: 400;
        font-size: 3rem;
        line-height: 140%;
        color: varr.$p_colour;
        margin-bottom: 4rem;
        transition: 0.3s all linear;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 160%;
        color: varr.$p_colour;
      }

      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 9rem;
        height: 9rem;
        background-color: #fafafa;
        border-radius: 50%;
        transition: 0.3s all linear;
      }
    }

    .offer_item:hover {
      h3 {
        color: #0a014f;
        font-weight: 500;
        transition: 0.3s all linear;
      }

      p {
        color: varr.$text_color;
        transition: 0.3s all linear;
      }

      > span {
        background-color: #0a014f;
        transition: 0.3s all linear;

        svg {
          width: 100% !important;
        }

        svg path {
          fill: #67bcff;
        }
      }
    }
  }

  .navigators {
    display: flex;
    justify-content: flex-end;
    padding: 5rem 8rem;
    gap: 3rem;

    @media (max-width: 960px) {
      padding: 4.5rem 6.1rem;
    }
    @media (max-width: 425px) {
      padding: 4rem 4rem;
    }

    > span {
      display: flex;
      width: 8rem;
      height: 8rem;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
    }

    > span:first-child {
      background-color: #d9d9d9;
    }

    > span:last-child {
      background-color: #0a014f;
      transform: rotate(180deg);

      path {
        fill: #ffffff;
      }
    }
  }

  .active_navigator {
    background-color: #0a014f !important;
    path {
      fill: #ffffff !important;
    }
  }

  .inactive_navigator {
    background-color: #d9d9d9 !important;
    path {
      fill: #373435 !important;
    }
  }

  .madeUp {
    background-color: #001219;
    padding: 8rem 8rem;
    @media (max-width: 960px) {
      padding: 5rem 6.1rem;
    }
    @media (max-width: 425px) {
      padding: 4rem 4rem;
    }
    .count {
      font-size: 10rem;
      font-weight: 400;
      color: #f1f5f9;
    }

    h1 {
      text-align: center;
      max-width: 490px;
      margin: 0 auto;
      font-size: 5.4rem;
      font-weight: 500;
      color: #f1f5f9;
      margin-bottom: 5rem;
    }

    > article {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      // justify-content: space-between;
      justify-content: center;
      margin: 0 auto;
      width: 100%;
      max-width: 800px;

      > div {
        border-right: 4px solid #ffffff;
        padding-right: 7rem;
        margin-right: 7rem;
        p {
          font-size: 1.4rem;
          font-weight: 400;
          color: #f1f5f9;
          text-align: center;
        }

        @media (max-width: 632px) {
          border: none;
          margin-right: 0;
        }
        @media (max-width: 523px) {
          padding-right: 3rem;
        }

        @media (max-width: 425px) {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0;
          margin-bottom: 10rem;
        }
      }
      > div:last-child {
        border: none;
        padding-right: 0;
        margin-right: 0;
      }
    }
  }
}

.more {
  text-align: center;
  margin-top: 5rem;
  a {
    text-decoration-line: underline;
    color: #3ec5f0;
    font-size: 2.4rem;
    font-weight: 500;
  }
}
