@use "../../globalStyles/variables.scss" as varr;

.mainContainer {
  margin-bottom: 4rem;
  padding: 6rem 8rem;

  @media (max-width: 960px) {
    padding: 5rem 6.1rem;
  }
  @media (max-width: 425px) {
    padding: 4rem 4rem;
  }
  .newsKeywords {
    display: flex;
    justify-content: space-evenly;
    background-color: #f1f5f9;
    flex-wrap: wrap;
    border: none;
    padding: varr.$padding;
    border-radius: 0.8rem;
    max-width: 1400px;
    margin: 0 auto;
    margin-bottom: 3.3rem;
    row-gap: 1.6rem;
    p {
      font-weight: 400;
      font-size: 1.8rem;
      color: #666666;
      cursor: pointer;
      padding: 0 1rem;
    }
    p:hover {
      color: #0880bd60;
    }
  }
}

.cardContainer {
  column-gap: 6rem;
  row-gap: 8rem;
  width: 100%;
  max-width: 1400px;
  display: grid;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  justify-content: space-around;

  h1 {
    display: none;
    text-align: none;
  }
}

.card {
  transition: 0.3s;
  height: auto;
  > div:first-child {
    width: 100%;
    max-height: 31rem;
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s linear;
    }
    img:hover {
      transform: scale(1.1, 1.1);
      transition: all 0.3s linear;
    }
  }

  > div:last-child {
    span {
      display: flex;
      align-items: center;
      gap: 2.5rem;
      margin-bottom: 1.5rem;

      > p {
        font-weight: 400;
        font-size: 2rem;
      }
    }
    h5 {
      font-weight: 700;
      font-size: 2.4rem;
      color: #0e1428;
      margin-bottom: 1rem;
    }
    a {
      font-weight: 400;
      font-size: 2.2rem;
      text-decoration-line: underline;
      color: #3ec5f0;
    }
  }
}

.card:hover {
  cursor: pointer;
}

.latestBlog {
  max-width: 1400px;
  min-height: 250px;
  @media (max-width: 500px) {
    min-height: 300px;
  }
  @media (max-width: 400px) {
    min-height: 400px;
  }
  margin: auto;
  margin-bottom: 3.4rem;
  position: relative;
  > section {
    width: 100%;
    height: 100%;
    min-height: inherit;
    border-radius: 1rem;
    overflow: hidden;
    @media (max-width: 900px) {
      filter: blur(2px);
    }
    img {
      width: 100%;
      height: 100%;
      min-height: inherit;
      object-fit: cover;
      transition: all 0.3s ease-in-out;
    }
    img:hover {
      transform: scale(1.2, 1.2);
      transition: all 0.3s ease-in-out;
    }
  }

  .latestContent {
    position: absolute;
    z-index: 2;
    height: 100%;
    min-height: inherit;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 3rem 6.4rem;
    @media (max-width: 500px) {
      padding: 3rem 4rem;
    }
    padding-bottom: 10rem;
    @media (max-width: 900px) {
      padding-bottom: 2rem;
      justify-content: center;
    }
    h1 {
      font-weight: 700;
      font-size: 5rem;
      color: #0e1428;
      max-width: 500px;
      margin-bottom: 0.5rem;
    }
    > p {
      font-weight: 400;
      font-size: 1.8rem;
      color: #333538;
      margin-bottom: 1rem;
    }

    > a {
      font-weight: 700;
      font-size: 2rem;
      text-decoration-line: underline;
      color: #3ec5f0;
    }

    span {
      display: flex;
      align-items: center;
      gap: 2rem;
      margin-bottom: 1rem;
      div {
        display: flex;
        align-items: center;
        gap: 1.2rem;
      }
      p {
        font-weight: 300;
        font-size: 1.6rem;
        color: #605e5e;
      }
    }
  }

  .latestContent:hover + section > img {
    transform: scale(1.2, 1.2);
    transition: all 0.3s ease-in-out;
  }
}

.frame {
  background-image: url("../../assets/news/innernews.svg");
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: varr.$general_gap;
  padding: 6rem;
  height: 64rem;
  background-size: cover;
  border-radius: 1rem;
  margin: 0 auto;
  margin-bottom: 3rem;
  max-width: 1400px;

  div {
    display: flex;
    width: 20%;
    p {
      white-space: nowrap;
      color: #605e5e;
      font-size: 1.5rem;
      width: 40%;
      line-height: 140%;
    }
  }
  h1 {
    font-size: 6rem;
    width: 40%;
    line-height: 65.5px;
    color: #0e1428;
  }
  p {
    font-size: 2rem;
    width: 40%;
    line-height: 180%;
    color: #333538;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 2rem;
}
.button {
  width: fit-content;
  border: none;
  background-color: transparent;
  font-size: 1.9rem;
  color: #3ec5f0;
  text-decoration: underline;
}
.newsText {
  display: flex;
  gap: 2rem;
}
