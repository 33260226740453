.container {
  padding: 6rem 8rem;

  @media (max-width: 960px) {
    padding: 5rem 6.1rem;
    padding-bottom: 10rem;
  }
  @media (max-width: 425px) {
    padding: 4rem 4rem;
  }
}

.careersTextContainer {
  display: flex;
  flex-direction: column;
}

.careersText {
  h1 {
    font-weight: 600;
    font-size: 4rem;
    color: #0e1428;
    text-align: center;
    margin-bottom: 3rem;
  }

  .picturesCard {
    display: grid;
    width: 70%;
    margin: auto;
    margin-bottom: calc(2rem + 13px);
    gap: 2rem;
    column-gap: 4rem;
    @media (max-width: 500px) {
      column-gap: 2rem;
      margin-bottom: calc(1rem + 13px);
    }
    grid-template-areas:
      "first second second"
      "third third fourth";

    .img1 {
      grid-area: first;
      margin-top: -2rem;
      @media (max-width: 500px) {
        margin-top: -1rem;
      }
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;
        object-fit: cover;
      }
      img:hover {
        transform: scale(1.1, 1.1);
        transition: all 0.3s ease-in-out;
      }
    }
    .img2 {
      grid-area: second;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;
        object-fit: cover;
      }
      img:hover {
        transform: scale(1.1, 1.1);
        transition: all 0.3s ease-in-out;
      }
    }
    .img3 {
      grid-area: third;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;
        object-fit: cover;
      }
      img:hover {
        transform: scale(1.1, 1.1);
        transition: all 0.3s ease-in-out;
      }
    }
    .img4 {
      grid-area: fourth;
      margin-top: 2rem;
      @media (max-width: 500px) {
        margin-top: 1rem;
      }
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;
        object-fit: cover;
      }
      img:hover {
        transform: scale(1.1, 1.1);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.topText {
  font-weight: 400;
  line-height: 180%;
  color: #333538;
  margin: 0 auto;
  margin-bottom: calc(2rem + 3rem);
  @media (max-width: 500px) {
    margin-bottom: calc(1rem + 3rem);
  }
  font-size: 1.8rem;
  text-align: center;
  max-width: 630px;
}

.nxtOpenPositions {
  margin-bottom: 10rem;
  max-width: 1400px;

  @media (min-width: 1400px) {
    margin: auto;
    margin-bottom: 10rem;
  }

  h1 {
    font-weight: 500;
    font-size: 4rem;
    margin-bottom: 2.1rem;
    color: #0e1428;
    @media (max-width: 600px) {
      font-size: 3rem;
    }
  }
  .nopositions {
    font-weight: 600;
    font-size: 1.8rem;
    margin-top: 1rem;
    text-align: center;
    color: #0e1428;
  }

  .nxtRoles {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 913px) {
      justify-content: center;
    }
    gap: 2.2rem;
  }
}

// @media screen and (max-width: 700px) {
//   .careersPageNews {
//     display: none;
//   }
// }
// @media screen and (max-width: 1000px) {
//   .careersPageNews {
//     display: none;
//   }
// }

// @media screen and (min-width: 1000px) {
//   .careersText {
//     font-weight: 600;
//     color: #0e1428;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;

//     .careersText h1 {
//       font-size: 56px;
//       margin-bottom: 18px;
//     }
//   }

//   .midText {
//     @extend .topText;
//     margin-top: 3rem;
//   }
// }
