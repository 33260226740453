@use "../../globalStyles/variables.scss" as varr;

.container {
  padding: 6rem 8rem;
  @media (max-width: 960px) {
    padding: 5rem 6.1rem;
  }
  @media (max-width: 425px) {
    padding: 4rem 4rem;
  }
  .topContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    margin-bottom: 10rem;

    @media (max-width: 541px) {
      flex-wrap: wrap-reverse;
    }

    img {
      width: 100%;
      max-width: 500px;
      object-fit: contain;
    }
  }

  .texts {
    // text-align: start;
    // justify-content: center;
    // width: 64rem;
    max-width: 550px;

    h1 {
      font-weight: 700;
      font-size: 4.8rem;
      line-height: 5.6rem;
      color: #0e1428;
      margin-bottom: 1.8rem;
    }

    p {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 180%;
      color: #333538;
    }
  }
  .contactDetails {
    padding: 4.5rem 4.2rem;
    background-color: varr.$contactusPage_bg;
    color: #fff;
    border-radius: 1rem;
    max-width: 1113px;
    margin: auto;
    margin-bottom: 6rem;

    h2 {
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.3rem;
      color: #ffffff;
      margin-bottom: 1rem;
    }
    p,
    a {
      font-weight: 300;
      font-size: 1.8rem;
      line-height: 180%;
      color: #ffffff;
      max-width: 322px;
    }

    .icons {
      display: flex;
      gap: 3rem;
    }

    .item {
      padding: 4rem 0;
      flex-basis: 40%;
      @media (max-width: 559px) {
        flex-basis: 100%;
        padding: 2rem 0;
      }

      span {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 1rem;

        a {
          border-right: 1px solid #c4c4c4;
          margin-right: 1.2rem;
          padding-right: 1.2rem;
          line-height: 100%;
          @media (max-width: 340px) {
            border-right: 1px solid transparent;
          }
        }
        a:last-child {
          border: none;
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }

  .link {
    text-decoration: underline;
    color: #3ec5f0;
    font-weight: 400;
    font-size: 2.8rem;
    line-height: 180%;
    text-transform: none;
    margin: auto;
  }

  .top,
  .bottom {
    display: flex;
    gap: 1rem;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .top {
    border-bottom: 1px solid #c4c4c4;
  }
}

.contactContents {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  padding: 4rem 0;
  border-radius: 1rem;

  h2 {
    font-weight: 500;
    font-size: 3rem;
    line-height: 3.4rem;
    margin-bottom: 3.4rem;
  }

  .inputForm {
    display: flex;
    width: 80%;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 3rem;
  }
  .form {
    padding: 2rem;
    border-radius: 2rem;
  }
  .button2 {
    background: #1f2f5e;
    border-radius: 2rem;
    color: #fff;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 180%;
    padding: 10px 43px;
  }
}

.inTouchWrap {
  display: flex;
  justify-content: center;
}

.dialogBox {
  border: 1px solid red;
}

.formContainer {
  background-color: white;
  border-radius: 1rem;
  padding: 4.5rem 8rem;
  width: 100vw;
  max-width: 700px;
  h3 {
    font-weight: 500;
    font-size: 3rem;
    line-height: 4.8rem;
    color: #0e1428;
  }

  form {
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2.4rem;
    }

    > section {
      margin-bottom: 2.3rem;
      label {
        display: block;
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.3rem;
        color: #0e1428;
        margin-bottom: 1.4rem;
      }
      input,
      textarea {
        width: 100%;
        border-radius: 1rem;
        padding: 1.9rem;
        border: 2px solid #c4c4c4;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 180%;
      }
      button {
        background: #060e24;
        border-radius: 10px;
        color: #ffffff;
        border: none;
        width: 100%;
        padding: 1.6rem;
        font-size: 1.8rem;
      }
    }
  }
}
